import { clientschedule } from "../constants/client";

export interface IChildList {
  id: string;
  parentFirstName: string;
  parentLastName: string;
  childFirstName: string;
  childLastName: string;
  childDateOfBirth: string | null;
  childGender: string;
  childLanguages: string[];
  businessUnit: string;
  concerns: string;
  hearAboutUs: string;
  insuranceProvider: string;
  clientType: string;
  messageCount:number;
  concernedStage: string[];
  onTrackStage: string;
  intakeStage: string;
  isEdit: boolean;
  clientProfile: any;
  createdAt: string;
  files: IFile[];
}
type FilesCategory =
  | "category"
  | "containerName"
  | "entityId"
  | "fileKey"
  | "fileUrl"
  | "fileName"
  | "id"
  | "type"
  | "updatedAt"
  | "uploadedAt"
  | "uploadedBy";

export type IFile = Record<FilesCategory, string>;

//Same as backend file category
export enum FileCategory {
  Intake="Intake",
  Assessment="Assessment", //for assessment form (pdf)
  Onboarding="Onboarding",
  Authorization="Authorization",
  Credentialing="Credentialing",
  Session="Session",
  Evaluation="Evaluation", //for assessment evaluation (png & pdf)
}



export interface Iform {
  id: string;
  name: string;
  url: string;
  embeddedCode: string;
  createdAt: string;
  updatedAt: string;
  isJot: boolean;
  isComplete: boolean;
}

export interface IOrganizationOptions {
  allowToCompleteCustomIntakeForm: null;
  allowToSendEmailToAdminSchedule?: null;
  allowToSendEmailToClientSchedule?: null;
  allowToSendEmailToProviderSchedule?: null;
  allowToSendReminderSchedule?: null;
  allowToSendSMSToClientSchedule?: null;
  allowToUploadBackInsuranceCard: null;
  allowToUploadFrontInsuranceCard: null;
  createdAt: string;
  form: IFormsAllData;
  descope_tenantId: string;
  id: string;
  orgLogo: {
    containerName: string;
    fileName: string;
    key: string;
    url: string;
  };
  orgName: string;
  orgUrl: string;
  updatedAt: string;
}

export type IFormsAllData = Record<"AssessmentForm"|"IntakeClientForm"|"IntakePacketForm"|"SessionForm" , IFormData[]>;

export interface IFormData {
  status: string;
  form: IForms;
  id: string;
}

export type Form = "createdAt" | "formCategory" | "formType" | "updatedAt";

export type IForm = Record<Form, string>;

export interface IForms extends IForm {
  url: null;
}


export interface IbusinessSettings {
  allowToCompleteCustomIntakeForm: boolean;
  allowToUploadBackInsuranceCard: boolean;
  allowToUploadFrontInsuranceCard: boolean;
  formURL?: string;
  formEmbeddedCode?: string;
}

export interface IChildDetails {
  client: IChildList;
  organizationOptions: IOrganizationOptions;
}

export interface IClientSchedule {
  id: string;
  descope_tenantId: string;
  status: string;
  specificDate: string;
  repeatWorkDays: string;
  repeatEndsOn: string;
  repeatService: number;
  repeatOn: string[];
  customDate: string;
  serviceType: string;
  serviceLocation: string;
  description: string;
  whoCancelled: null;
  cancellationReason: null;
  createdBy: string;
  fromTime: string;
  toTime: string;
  authId: string;
  authCode: string;
  createdAt: string;
  updatedAt: string;
}

export interface IScheduleList {
  ChildList: IChildList[];
  currentChild: IChildDetails;
  clientschedulelist: IClientSchedule[];
  clientFiles: IFile[];
  ischildListLoading: boolean;
}
