import React, { useCallback } from "react";
import Alert from "../../assets/images/Image-svg/alert.svg";
import ABAEngineLogo from "../../assets/images/logos/abaengine.png";
import "./errorPage.scss";
import { useDescope } from "@descope/react-sdk";
import { useAppDispatch } from "../../redux/store";
import { resetCurrentTenant } from "../../redux/features/current-tenant/current-tenant-slice";

const ErrorPage: React.FC<{ loginError: boolean }> = ({
  loginError,
}) => {
  const { logout } = useDescope();
  const dispatch = useAppDispatch();
  
  //handles logout
  const handleUserLogout = useCallback(() => {
    dispatch(resetCurrentTenant(""));
    logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logout]);
  return (
    <div className="errorPage">
      {loginError && (
        <div className="errorHeader">
          <img alt="logo" src={ABAEngineLogo} height={"50px"} />
        </div>
      )}
      <div className="errorbody">
        <div className="errorPage__container">
          <img src={Alert} alt="alertIcon" height={"60px"} />
          <h2>There was an error.</h2>
          {loginError ? (
            <>
              <p>
                It seems that you do not have permission to access Parent
                Portal.
              </p>
              <p>Please logout or try different browser to login again.</p>
            </>
          ) : (
            <>
              <p>
                It seems that you account has not been configured to view
                patient information yet.
              </p>
              <p>
                Please contact with your healthcare provider to get this setup
                first. After that, you can login again
              </p>
            </>
          )}

          <button className="logOut-btn" onClick={handleUserLogout}>
            Log Out
          </button>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
