import React, { useEffect, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  useSession,
  useUser,
  useDescope,
} from "@descope/react-sdk";
import Header from "../component/header/Header";

import { useAppDispatch } from "../redux/store";
import { resetCurrentTenant } from "../redux/features/current-tenant/current-tenant-slice";
import { getRolesForTenant } from "../utils";
import ErrorPage from "../pages/error/ErrorPage";

const PrivateRoute: React.FC<{ children: any }> = ({ children }) => {
  const navigate = useNavigate();
  const { logout } = useDescope();
  const dispatch = useAppDispatch();
  const { isAuthenticated } = useSession();

  const { user } = useUser();
  // console.log({ user });
  const currentTenantId = user?.customAttributes?.currentTenantId;
  const currentRoles = useMemo(() => {
    return getRolesForTenant(user, currentTenantId);
  }, [user, currentTenantId]);
  
  // const sessionToken = getSessionToken();


  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  //user.roles & user.tenantId came from active one, may not be the login one
  if (user) {
    //find tenant that has roles key as Parent
    // const parentTenant = user?.tenants?.filter(
    //   (tenant: any) => tenant.roles[0].key === "Parent");

    if (!currentRoles?.includes("Parent")) {
      return (
        <div>
          <ErrorPage loginError = {true}/>
          {/* <span>Only Parent has permission to use parent portal</span>
          <button onClick={handleUserLogout}>Logout</button> */}
        </div>
      );
    }
  }

  return isAuthenticated ? (
    <div>
      <Header />
      {children}
    </div>
  ) : (
    <></>
  );
};

export default PrivateRoute;
