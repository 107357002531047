import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useUser } from "@descope/react-sdk";
import { IChildList } from "../../type/child-info";
import HomeSubPage from "./home.page";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { getChildList } from "../../redux/features/client-main/client-list-slice";
// import { ContextHolder, useAuth } from "@frontegg/react";
import { getOrgInfo } from "../../redux/features/organization/organization-slice";
import ErrorPage from "../error/ErrorPage";

const HomePage: React.FC = () => {
  //#region variable region

  // get user data and auth
  // const { user, isAuthenticated } = useAuth();

  const { user } = useUser();

  //dispatch
  const dispatch = useAppDispatch();

  //store Childs Lists
  const ChildDataLists = useAppSelector((state) => state.clientSlice.ChildList);
  const isChildListLoading = useAppSelector(
    (state) => state.clientSlice.ischildListLoading
  );

  // const currentTenant = useAppSelector((state) => state.currentTenantSlice);

  //store selected tabs
  const [selectedChild, setSelectedChild] = useState<IChildList>(
    ChildDataLists[0]
  );

  //#endregion

  //#region method region

  //handle tabs data
  const handleTabData = (ID: string) => {
    let data = ChildDataLists.filter((item: IChildList) => {
      return item.id === ID;
    });
    setSelectedChild(data[0]);
    localStorage.setItem("selectedChild", JSON.stringify(data[0]));
  };

  //#endregion

  //#region useEffect region

  //re-fetching selected childs
  // useEffect(() => {
  //   if (selectedChild === undefined && ChildDataLists.length > 0) {
  //     setSelectedChild(ChildDataLists[0]);
  //   }
  // }, [ChildDataLists, selectedChild]);

  //fetch child list
  useEffect(() => {
    if (user?.email) {
      dispatch(getChildList({ email: user.email! }));
    }
  }, [user?.email]);

  useEffect(() => {
    let value: any = localStorage.getItem("selectedChild");
    if (value) {
      setSelectedChild(JSON.parse(value));
    } else {
      if (ChildDataLists) {
        setSelectedChild(ChildDataLists[0]);
      }
    }
  }, [ChildDataLists?.length]);

  useEffect(() => {
    localStorage.removeItem("selectedChild");
  }, []);

  //refetch token
  // useEffect(() => {
  //   if (isAuthenticated && user?.accessToken !== undefined) {
  //     //MAGIC here!!! to ensure ContextHolder.AccessToken also updated from user.accessToken

  //     ContextHolder.setAccessToken(user?.accessToken);
  //     ContextHolder.setUser(user);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isAuthenticated, user?.accessToken]);
  //#endregion

  //fetch orgInfo
  useEffect(() => {
    dispatch(getOrgInfo());
  }, []);
  //#endregion

  // console.log("ChildDataLists:", ChildDataLists);

  return (
    <div>
      {ChildDataLists.length === 0 && isChildListLoading === false && (
        <ErrorPage loginError={false} />
      )}
      {ChildDataLists.length > 0 && (
        <Box className="pageTemplate">
          <ul
            className="innerTabs"
            style={{
              margin: "40px 0",
              overflowX: "scroll",
              overflowY: "hidden",
            }}
          >
            {ChildDataLists.map((item: IChildList, index: number) => {
              return (
                <li className="innerTabs__item" key={index}>
                  <button
                    style={{ display: "flex", width: "max-content" }}
                    onClick={() => handleTabData(item?.id)}
                    className={item?.id === selectedChild?.id ? "active" : ""}
                  >
                    {`${item?.childFirstName}${" "}${item?.childLastName}`}
                  </button>
                </li>
              );
            })}
          </ul>
          {selectedChild?.id && (
            <>
              <HomeSubPage ChildData={selectedChild} />
            </>
          )}
        </Box>
      )}
    </div>
  );
};

export default HomePage;
