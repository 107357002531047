import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { DefaultChildDetail } from "../../../constants/client";
import ClientService from "../../../service/client.service";
import {
  IChildDetails,
  IChildList,
  IClientSchedule,
  IScheduleList,
} from "../../../type/child-info";
import { setResponseValue } from "../api-response/api-response";

const intialState: IScheduleList = {
  ChildList: [],
  currentChild: DefaultChildDetail,
  clientschedulelist: [],
  clientFiles: [],
  ischildListLoading: true,
};

const clientSlice = createSlice({
  name: "clientSlice",
  initialState: intialState,
  reducers: {
    setClientFilesFromResponse: (state, action) => {
      state.clientFiles = [...action.payload];
      return state;
    },
    setChildListLoading: (state, action) => {
      state.ischildListLoading = action.payload;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getChildList.fulfilled,
      (state, action: PayloadAction<IChildList[]>) => {
        return {
          ...state,
          ChildList: [...action.payload],
        };
      }
    );
    builder.addCase(
      getChildDetails.fulfilled,
      (state, action: PayloadAction<IChildDetails>) => {
        return {
          ...state,
          currentChild: {
            ...action.payload,
          },
        };
      }
    );
    // builder.addCase(
    //   getChildSchedules.fulfilled,
    //   (state, action: PayloadAction<IClientSchedule[]>) => {
    //     return {
    //       ...state,
    //       clientschedulelist: [...action.payload],
    //     };
    //   }
    // );
  },
});

//Get list of child
export const getChildList = createAsyncThunk(
  "/parent/getChildList",
  async ({ email }: { email: string }, { getState, dispatch }) => {
    try {
      // dispatch(setChildListLoading(true));
      // dispatch(setResponseValue({ name: "pending", value: true }));
      const { data } = await ClientService.getChildsByemail(email);
      dispatch(setChildListLoading(false));
      return data;
    } catch (e: any) {
      // throw e?.response?.data;
      throw dispatch(
        setResponseValue({
          name: "message",
          value: e?.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

//Get Child Details

export const getChildDetails = createAsyncThunk(
  "/parent/child",
  async ({ id }: { id: string }, { getState, dispatch }) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { data } = await ClientService.getChildById(id);
      return data;
    } catch (e: any) {
      // throw e?.response?.data;
      throw dispatch(
        setResponseValue({
          name: "message",
          value: e.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

// // get ChildSchedules
// export const getChildSchedules = createAsyncThunk(
//   "/parent/child/clientId/getSchedules",
//   async ({ id }: { id: string }, { getState, dispatch }) => {
//     try {
//       dispatch(setResponseValue({ name: "pending", value: true }));
//       const { data } = await ClientService.getChildScheduleById(id);

//       return data;
//     } catch (e: any) {
//       // e?.response?.data;
//       throw dispatch(
//         setResponseValue({
//           name: "message",
//           value: e.message,
//         })
//       );
//     } finally {
//       dispatch(setResponseValue({ name: "pending", value: false }));
//     }
//   }
// );

// get Child Files
export const getClientFilesById = createAsyncThunk(
  "clientListSlice/getClientFilesById",
  async ({ clientId }: { clientId: string }, { getState, dispatch }) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const data = await ClientService.getClientFilesById(clientId);

      if (data.status) {
        dispatch(setClientFilesFromResponse(data.data));
        return data;
      }
    } catch (e: any) {
      dispatch(
        setResponseValue({
          name: "message",
          value: e.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

// get Child Files
export const patchIntakeformstatusById = createAsyncThunk(
  "/organization/business/new/form/",
  async ({ formId }: { formId: string }, { getState, dispatch }) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const data = await ClientService.patchIntakeformstatusById(formId);

      if (data.status) {
        dispatch(setClientFilesFromResponse(data.data));
        return data;
      }
    } catch (e: any) {
      dispatch(
        setResponseValue({
          name: "message",
          value: e.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const updateLastLogin = createAsyncThunk(
  "user/updateLastLogin",
  async (
    {
      email,
    }: {
      email: string;
    },
    { dispatch }
  ) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { status, message } = await ClientService.updateLastLogIn(email);

      if (status) {
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: e?.response?.data?.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

// file submit handler

// export const updateDocumentFile = createAsyncThunk(
//   "client/updateFile",
//   async (
//     { clientId, newFiles }: { clientId: string; newFiles: any[] },
//     { getState, dispatch }
//   ) => {

//     try {
//       dispatch(setResponseValue({ name: "pending", value: true }));
//       const { status, message } = await ClientService.postClientFiles(
//         "Intake",
//         "Client",
//         "Client",
//         clientId,
//         newFiles
//       );

//       if (status) {
//         // dispatch(setClientFilesDataChange({}));
//         dispatch(setResponseValue({ name: "success", value: true }));
//         dispatch(setResponseValue({ name: "message", value: message }));
//       }
//     } catch (e: any) {
//       dispatch(setResponseValue({ name: "error", value: true }));
//       dispatch(setResponseValue({ name: "message", value: e?.message }));
//     } finally {
//       dispatch(setResponseValue({ name: "pending", value: false }));
//     }
//   })

export const { setClientFilesFromResponse,setChildListLoading } = clientSlice.actions;

export default clientSlice;
